import React from 'react'
import Footer from 'components/Footer'
import IndexNavbar from 'components/Navbars/IndexNavbar'
import { Helmet } from "react-helmet";

export const Terms = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Priya | Terms &amp; Conditions</title>
                <meta name="title" content="Priya | Terms &amp; Conditions" />
            </Helmet>
            <section className="py-0 bg-dark nav-section">
                <IndexNavbar className="fixed-top" />
            </section>
            <section className="bg-white">
                <h2 className='ff-bold text-dark mb-4'>Terms &amp; Conditions</h2>
                <p className='text-dark ff-inter-regular mb-4'>Effective: November 1, 2023</p>
                <p className='text-dark ff-inter-regular mb-4'>Thank you for using Priya!</p>
                <p className='text-dark ff-inter-regular mb-4'>These Terms of Use apply to your use of Priya and other services for individuals, along with any associated software applications and websites (all together, “Services”). These Terms form an agreement between you and Y CORP, a Nevada company. By using our Services, you agree to these Terms</p>

                <h2 className='ff-bold text-dark mb-4'>Who we are</h2>
                <p className='text-dark ff-inter-regular mb-4'>Y CORP is an AI research and deployment company. Our mission is to build sophisticated AI to understand reality and improve society. For more information about Priya, please visit https://priya.ai/about.</p>

                <h2 className='ff-bold text-dark mb-4'>Registration and Access</h2>
                <p className='text-dark ff-inter-regular mb-4'>Minimum Age. You must be at least 13 years old or the minimum age required in your country toconsent to use the Services. If you are under 18 you must have your parent or legal guardian’s permission to use the Services.</p>
                <p className='text-dark ff-inter-regular mb-4'>Registration. You must provide accurate and complete information to register for an account to use our Services. You may not share your account credentials or make your account available to anyone else and are responsible for all activities that occur under your account. If you create an account or use the Services on behalf of another person or entity, you must have the authority to accept these Terms on their behalf.</p>

                <h2 className='ff-bold text-dark mb-4'>Using Our Services</h2>
                <p className='text-dark ff-inter-regular mb-4'>What You Can Do. Subject to your compliance with these Terms, you may access and use our Services. In using our Services, you must comply with all applicable laws and any other documentation, guidelines, or policies we make available to you</p>
                <p className='text-dark ff-inter-regular mb-4'>What You Cannot Do. You may not use our Services for any illegal, harmful, or abusive activity. For example, you may not:</p>

                <ul style={{ listStyle: 'disc', marginLeft: '15px' }}>
                    <li className='text-dark ff-inter-regular'>Use our Services in a way that infringes, misappropriates or violates anyone’s rights.</li>
                    <li className='text-dark ff-inter-regular'>Modify, copy, lease, sell or distribute any of our Services.</li>
                    <li className='text-dark ff-inter-regular'>Attempt to or assist anyone to reverse engineer, decompile or discover the source code or underlying components of our Services, including our models, algorithms, or systems (except to the extent this restriction is prohibited by applicable law).</li>
                    <li className='text-dark ff-inter-regular'>Automatically or programmatically extract data or Output (defined below).</li>
                    <li className='text-dark ff-inter-regular'>Represent that Output was human-generated when it was not.</li>
                    <li className='text-dark ff-inter-regular'>Interfere with or disrupt our Services, including circumvent any rate limits or restrictions or bypass any protective measures or safety mitigations we put on our Services.</li>
                    <li className='text-dark ff-inter-regular'>Use Outputted prices or any other models to develop further models that compete with Y CORP and any affiliated products & services.</li>
                </ul>

                <p className='text-dark ff-inter-regular mb-4'>Software. Our Services may allow you to download software, such as mobile applications, which may update automatically to ensure you’re using the latest version. Our software may include open source software that is governed by its own licenses that we’ve made available to you.</p><p className='text-dark ff-inter-regular mb-4'>Pricing Updates. By connecting to and/or using our Software you agree to allow access to regular pricing updates to your products and/or services conducted by our Software on a regular basis as determined by our Software. You also agree to allow our software to collect sales data, behavior data, and access your connected website and/or Shopify store data to allow our software better accuracy and performance over time.</p>
                <p className='text-dark ff-inter-regular mb-4'>Corporate Domains. If you create an account using an email address owned by an organization (for example, your employer), that account may be added to the organization's business account with us, in which case we will provide notice to you so that you can help facilitate the transfer of your account (unless your organization has already provided notice to you that it may monitor and control your account). Once your account is transferred, the organization’s administrator will be able to control your account, including being able to access Content (defined below) and restrict or remove your access to the account.</p>
                <p className='text-dark ff-inter-regular mb-4'>Third Party Services. Our services may include third party software, products, or services, (“Third Party Services”) and some parts of our Services, like our browse feature, may include output from those services (“Third Party Output”). Third Party Services and Third Party Output are subject to their own terms, and we are not responsible for them.</p>
                <p className='text-dark ff-inter-regular mb-4'>Feedback. We appreciate your feedback, and you agree that we may use it without restriction or compensation to you.</p>

                <h2 className='ff-bold text-dark mb-4'>Our IP Rights</h2>
                <p className='text-dark ff-inter-regular mb-4'>We and our affiliates own all rights, title, and interest in and to the Services.</p>

                <h2 className='ff-bold text-dark mb-4'>Paid Accounts</h2>
                <p className='text-dark ff-inter-regular mb-4'>Billing. If you purchase any Services, you will provide complete and accurate billing information, including a valid payment method. For paid subscriptions, we will automatically charge your payment method on each agreed-upon periodic renewal until you cancel. You’re responsible for all applicable taxes, and we’ll charge tax when required. If your payment cannot be completed, we may downgrade your account or suspend your access to our Services until payment is received.</p>
                <p className='text-dark ff-inter-regular mb-4'>Cancellation. You can cancel your paid subscription at any time. Payments are non-refundable, except where required by law. These Terms do not override any mandatory local laws regarding your cancellation rights.</p>
                <p className='text-dark ff-inter-regular mb-4'>Changes. We may change our prices from time to time. If we increase our subscription prices, we will give you at least 30 days’ notice and any price increase will take effect on your next renewal so that you can cancel if you do not agree to the price increase.</p>


                <h2 className='ff-bold text-dark mb-4'>Termination and Suspension</h2>
                <p className='text-dark ff-inter-regular mb-4'>Termination. You are free to stop using our Services at any time. We reserve the right to suspend or terminate your access to our Services or delete your account if we determine:</p>
                <ul style={{ listStyle: 'disc', marginLeft: '15px' }}>
                    <li className='text-dark ff-inter-regular'>You breached these Terms</li>
                    <li className='text-dark ff-inter-regular'>We must do so to comply with the law</li>
                    <li className='text-dark ff-inter-regular'>Your use of our Services could cause risk or harm to Y CORP, our users, or anyone else.</li>
                </ul>
                <p className='text-dark ff-inter-regular mb-4'>We also may terminate your account if it has been inactive for over a year and you do not have a paid account. If we do, we will provide you with advance notice</p>
                <p className='text-dark ff-inter-regular mb-4'>Appeals. If you believe we have suspended or terminated your account in error, you can file an appeal with us by contacting support@ycorp.ai.</p>

                <h2 className='ff-bold text-dark mb-4'>Discontinuation of Services</h2>
                <p className='text-dark ff-inter-regular mb-4'>We may decide to discontinue our Services, but if we do, we will give you advance notice and a refund for any prepaid, unused Services.</p>

                <h2 className='ff-bold text-dark mb-4'>Disclaimer of Warranties</h2>
                <p className='text-dark ff-inter-regular mb-4'>OUR SERVICES ARE PROVIDED “AS IS.” EXCEPT TO THE EXTENT PROHIBITED BY LAW, WE AND OUR AFFILIATES AND LICENSORS MAKE NO WARRANTIES (EXPRESS, IMPLIED, STATUTORY OR OTHERWISE) WITH RESPECT TO THE SERVICES, AND DISCLAIM ALL WARRANTIES INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, NON-INFRINGEMENT, AND QUIET ENJOYMENT, AND ANY WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR TRADE USAGE. WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, ACCURATE OR ERROR FREE, OR THAT ANY CONTENT WILL BE SECURE OR NOT LOST OR ALTERED.</p>
                <p className='text-dark ff-inter-regular mb-4'>YOU ACCEPT AND AGREE THAT ANY USE OF OUTPUTS FROM OUR SERVICE IS AT YOUR SOLE RISK AND YOU WILL NOT RELY ON OUTPUT AS A SOLE SOURCE OF TRUTH OR FACTUAL INFORMATION, OR AS A SUBSTITUTE FOR PROFESSIONAL ADVICE.</p>

                <h2 className='ff-bold text-dark mb-4'>Limitation of Liability</h2>
                <p className='text-dark ff-inter-regular mb-4'>NEITHER WE NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, OR DATA OR OTHER LOSSES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY UNDER THESE TERMS WILL NOT priya_ai THE GREATER OF THE AMOUNT YOU PAID FOR THE SERVICE THAT GAVE RISE TO THE CLAIM DURING THE 12 MONTHS BEFORE THE LIABILITY AROSE OR ONE HUNDRED DOLLARS ($100). THE LIMITATIONS IN THIS SECTION APPLY ONLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.</p>
                <p className='text-dark ff-inter-regular mb-4'>Some countries and states do not allow the disclaimer of certain warranties or the limitation of certain damages, so some or all of the terms above may not apply to you, and you may have additional rights. In that case, these Terms only limit our responsibilities to the maximum extent permissible in your country of residence.</p>
                <p className='text-dark ff-inter-regular mb-4'>Y CORP’S AFFILIATES, SUPPLIERS, LICENSORS, AND DISTRIBUTORS ARE INTENDED THIRD PARTY BENEFICIARIES OF THIS SECTION.</p>

                <h2 className='ff-bold text-dark mb-4'>Indemnity</h2>
                <p className='text-dark ff-inter-regular mb-4'>If you are a business or organization, to the extent permitted by law, you will indemnify and hold harmless us, our affiliates, and our personnel, from and against any costs, losses, liabilities, and expenses (including attorneys’ fees) from third party claims arising out of or relating to your use of he Services and Content or any violation of these Terms.</p>

                <h2 className='ff-bold text-dark mb-4'>Dispute Resolution</h2>
                <p className='text-dark ff-inter-regular mb-4'>YOU AND Y CORP AGREE TO THE FOLLOWING MANDATORY ARBITRATION AND CLASS ACTION WAIVER PROVISIONS:</p>
                <p className='text-dark ff-inter-regular mb-4'>MANDATORY ARBITRATION. You and Y CORP agree to resolve any claims arising out of or relating to these Terms or our Services, regardless of when the claim arose, even if it was before these Terms existed (a “Dispute”), through final and binding arbitration</p>
                <p className='text-dark ff-inter-regular mb-4'>Informal Dispute Resolution. We would like to understand and try to address your concerns prior to formal legal action. Before either of us files a claim against the other, we both agree to try to resolve the Dispute informally. We will do so by sending you notice to the email address associated with your account. If we are unable to resolve a Dispute within 60 days, either of us has the right to initiate arbitration. We also both agree to attend an individual settlement conference if either party requests one during this time. Any statute of limitations will be tolled during this informal resolution process.</p>
                <p className='text-dark ff-inter-regular mb-4'>Arbitration Forum. If we are unable to resolve the Dispute, either of us may commence arbitration with National Arbitration and Mediation (“NAM”) under its Comprehensive Dispute Resolution Rules and Procedures and/or Supplemental Rules for Mass Arbitration Filings, as applicable. Y CORP will not seek attorneys’ fees and costs in arbitration unless the arbitrator determines that your claim is frivolous. The activities described in these Terms involve interstate commerce and the Federal Arbitration Act will govern the interpretation and enforcement of these arbitration terms and any arbitration.</p>
                <p className='text-dark ff-inter-regular mb-4'>Arbitration Procedures. The arbitration will be conducted by videoconference if possible, but if the arbitrator determines a hearing should be conducted in person, the location will be mutually agreed upon, in the county where you reside, or as determined by the arbitrator, unless the batch arbitration process applies. The arbitration will be conducted by a sole arbitrator. The arbitrator will be either a retired judge or an attorney licensed to practice law in the state of California. The arbitrator will have exclusive authority to resolve any Dispute, except the state or federal courts of San Francisco, California have the authority to determine any Dispute about enforceability, validity of the class action waiver, or requests for public injunctive relief, as set out below. Any settlement offer amounts will not be disclosed to the arbitrator by either party until after the arbitrator determines the final award, if any. The arbitrator has the authority to grant motions dispositive of all or part of any Dispute.</p>
                <p className='text-dark ff-inter-regular mb-4'>Exceptions. This section does not require informal dispute resolution or arbitration of the following claims: (i) individual claims brought in small claims court; and (ii) injunctive or other equitable relief to stop unauthorized use or abuse of the Services or intellectual property infringement or misappropriation.</p>
                <p className='text-dark ff-inter-regular mb-4'>CLASS AND JURY TRIAL WAIVERS. You and Y CORP agree that Disputes must be brought on an individual basis only, and may not be brought as a plaintiff or class member in any purported class, consolidated, or representative proceeding. Class arbitrations, class actions, and representative actions are prohibited. Only individual relief is available. The parties agree to sever and litigate in court any request for public injunctive relief after completing arbitration for the underlying claim and all other claims. This does not prevent either party from participating in a class-wide settlement. You and Y CORP knowingly and irrevocably waive any right to trial by jury in any action, proceeding, or counterclaim.</p>
                <p className='text-dark ff-inter-regular mb-4'>Batch Arbitration. If 25 or more claimants represented by the same or similar counsel file demands for arbitration raising substantially similar Disputes within 90 days of each other, then you and Y CORP agree that NAM will administer them in batches of up to 50 claimants each (“Batch”), unless there are less than 50 claimants in total or after batching, which will comprise a single Batch. NAM will administer each Batch as a single consolidated arbitration with one arbitrator, one set of arbitration fees, and one hearing held by videoconference or in a location decided by the arbitrator for each Batch. If any part of this section is found to be invalid or unenforceable as to a particular claimant or Batch, it will be severed and arbitrated in individual proceedings.</p>
                <p className='text-dark ff-inter-regular mb-4'>Severability. If any part of these arbitration terms is found to be illegal or unenforceable, the remainder will remain in effect, except that if a finding of partial illegality or unenforceability would allow class arbitration, class action, or representative action, this entire dispute resolution section will be unenforceable in its entirety.</p>

                <h2 className='ff-bold text-dark mb-4'>Copyright Complaints</h2>
                <p className='text-dark ff-inter-regular mb-4'>If you believe that your intellectual property rights have been infringed, please send notice by emailing us at support@ycorp.ai. We may delete or disable content that we believe violates these Terms or is alleged to be infringing and will terminate accounts of repeat infringers where appropriate.</p>

                <h2 className='ff-bold text-dark mb-4'>General Terms</h2>
                <p className='text-dark ff-inter-regular mb-4'>Assignment. You may not assign or transfer any rights or obligations under these Terms and any attempt to do so will be void. We may assign our rights or obligations under these Terms to any affiliate, subsidiary, or successor in interest of any business associated with our Services.</p>
                <p className='text-dark ff-inter-regular mb-4'>Changes to These Terms or Our Services. We are continuously working to develop and improve our Services. We may update these Terms or our Services accordingly from time to time. For example, we may make changes to these Terms or the Services due to:</p>

                <ul style={{ listStyle: 'disc', marginLeft: '15px' }}>
                    <li className='text-dark ff-inter-regular'>Changes to the law or regulatory requirements.</li>
                    <li className='text-dark ff-inter-regular'>Security or safety reasons.</li>
                    <li className='text-dark ff-inter-regular'>Circumstances beyond our reasonable control.</li>
                    <li className='text-dark ff-inter-regular'>Changes we make in the usual course of developing our Services.</li>
                    <li className='text-dark ff-inter-regular'>To adapt to new technologies.</li>
                </ul>

                <p className='text-dark ff-inter-regular mb-4'>We will give you at least 30 days advance notice of changes to these Terms that materially adversely impact you either via email or an in-product notification. All other changes will be effective as soon as we post them to our website. If you do not agree to the changes, you must stop using our Services.</p>
                <p className='text-dark ff-inter-regular mb-4'>Delay in Enforcing These Terms. Our failure to enforce a provision is not a waiver of our right to do so later. Except as provided in the dispute resolution section above, if any portion of these Terms is determined to be invalid or unenforceable, that portion will be enforced to the maximum extent permissible and it will not affect the enforceability of any other terms.</p>
                <p className='text-dark ff-inter-regular mb-4'>Trade Controls. You must comply with all applicable trade laws, including sanctions and export control laws. Our Services may not be used in or for the benefit of, or exported or re-exported to (a) any U.S. embargoed country or territory or (b) any individual or entity with whom dealings are prohibited or restricted under applicable trade laws. Our Services may not be used for any end use prohibited by applicable trade laws, and your Input may not include material or information that requires a government license for release or export.</p>
                <p className='text-dark ff-inter-regular mb-4'>Entire Agreement. These Terms contain the entire agreement between you and Y CORP regarding the Services and, other than any Service-specific terms, supersedes any prior or contemporaneous agreements between you and Y CORP.</p>
                <p className='text-dark ff-inter-regular mb-4'>Governing Law. Florida law will govern these Terms except for its conflicts of laws principles. Except as provided in the dispute resolution section above, all claims arising out of or relating to these Terms will be brought exclusively in the federal or state courts of Miami, Florida.</p>

            </section>
            <Footer />
        </>
    )
}
