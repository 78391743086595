import React from "react";
import DashboardImage from "../../../assets/img/dashboard7.png";
import thunderIcon from "../../../assets/img/light 1.png";
import { Col, Row } from "reactstrap";
import "./autoPilotPricing.css";
import PrimaryButton from "components/ui/PrimaryButton";

export default function AutoPilotPricing() {
  return (
    <div className="autopilot-container" style={{ paddingBottom: "100px" }}>
      <Row className="d-flex flex-column  flex-lg-row">
        <Col style={{ marginLeft: 10 }} className="px-md-5">
          <div className="autopilot-image">
            <img
              src={DashboardImage}
              alt="Description of the image"
              layout="responsive"
            />
          </div>
        </Col>

        <Col className="autoPilotContent">
          <div className="autoPilot-header">
            <img src={thunderIcon} alt="search Icon" width={22} height={26} />
            <div className="autoPilot-title">AUTOPILOT PRICING™</div>
          </div>
          <p className="autopilot-description">
            Put your prices on autopilot to allow Priya AI to maximize your
            profit
          </p>
          <div className="gap-2 d-flex justify-content-md-start justify-content-lg-start mt-4">
            <PrimaryButton backgroundColor='black' color='white' title='TRY AUTOPILOT PRICING™' />
          </div>
        </Col>
      </Row>
    </div>
  );
}
