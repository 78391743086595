import grid from "../../../assets/img/Demo/auth_grid.png";
import logo from "../../../assets/img/Demo/logo-black1.png";
import "./index.css";

import auth_trusted_1 from "../../../assets/img/Demo/auth_trusted_1.png";
import auth_trusted_2 from "../../../assets/img/Demo/auth_trusted_2.png";
import auth_trusted_3 from "../../../assets/img/Demo/auth_trusted_3.png";
import auth_trusted_4 from "../../../assets/img/Demo/auth_trusted_4.png";
import auth_trusted_5 from "../../../assets/img/Demo/auth_trusted_5.png";
import auth_trusted_6 from "../../../assets/img/Demo/auth_trusted_6.png";
import auth_trusted_7 from "../../../assets/img/Demo/auth_trusted_7.png";
import auth_trusted_8 from "../../../assets/img/Demo/auth_trusted_8.png";
import auth_trusted_9 from "../../../assets/img/Demo/auth_trusted_9.png";
import auth_trusted_10 from "../../../assets/img/Demo/auth_trusted_10.png";
import auth_trusted_11 from "../../../assets/img/Demo/auth_trusted_11.png";
import Config from '../../../configs/config.js'

import CalendlyWidget from "./CalendlyWidget";

function LoginPage() {
    const imagesData1 = [
        auth_trusted_1,
        auth_trusted_2,
        auth_trusted_3,
        auth_trusted_4,
        auth_trusted_5,
        auth_trusted_6,
    ];
    const imagesData2 = [
        auth_trusted_7,
        auth_trusted_8,
        auth_trusted_9,
        auth_trusted_10,
        auth_trusted_11,
    ];

    const miniImagesData1 = [
        auth_trusted_1,
        auth_trusted_2,
        auth_trusted_3,
        auth_trusted_4,
    ];
    const miniImagesData2 = [
        auth_trusted_5,
        auth_trusted_6,
        auth_trusted_7,
        auth_trusted_8,
    ];
    const miniImagesData3 = [
        auth_trusted_9,
        auth_trusted_10,
        auth_trusted_11,
    ];

    const min768ImageData1 = [
        auth_trusted_1,
        auth_trusted_2,
        auth_trusted_3,
    ];
    const min768ImageData2 = [
        auth_trusted_4,
        auth_trusted_5,
        auth_trusted_6,
    ];
    const min768ImageData3 = [auth_trusted_7, auth_trusted_8];

    return (
        // <OldLoginPage />
        <div className="demo-container">
            <div className="logo">
                <a href={"https://priya.ai/"}>
                    <img
                        src={logo}
                        alt="Priya logo"
                        className="max-500:w-[125px]"
                    />
                </a>
            </div>
            <div className="bg">
                <img src={grid} alt="Background Image" style={{height: '30vh', width: "100%", position: "absolute", zIndex: 0}}/>
            </div>
            <div className="body">
                <div className="body-content">
                    <div className="demo-title">
                        Book a Call To Get Started
                    </div>
                    <div className="demo-subtitle">
                        We&apos;ll give you a demo and guide you through setup.
                    </div>
                </div>
                <CalendlyWidget/>
                <div className="demo-footer">
                    <div className="already-have-account">
                        <div className="text-[#7A7A7A]">Already have an account?</div>
                        <a href={`${Config.DASHBOARD_URL}/signin`} className="text-[#00C1EF]">
                            Login here
                        </a>
                    </div>
                    <div className="demo-footer__title">
                        Trusted by 300+ Shopify&apos;s fastest growing brands
                    </div>

                    <div className="demo-images demo-images-md">
                        <div className="demo-images__row">
                            {imagesData1.map((img, i) => (
                                <div
                                    key={i}
                                    className={`demo-images__item border ${i === 0 && "border-l-0"} ${i === imagesData1.length - 1 && "border-r-0"}`}
                                >
                                    <img
                                        src={img}
                                        alt="trusted brands logo"
                                        className="demo-images__img"
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="demo-images__row">
                            {imagesData2.map((img, i) => (
                                <div
                                    key={i}
                                    className={`demo-images__item border ${i === 0 && "border-l-0"} ${i === imagesData2.length - 1 && "border-r-0"}`}
                                >
                                    <img
                                        src={img}
                                        alt="trusted brands logo"
                                        className="demo-images__img"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="demo-images demo-images-sm">
                        <div className="demo-images__row">
                            {miniImagesData1.map((img, i) => (
                                <div
                                    key={i}
                                    className={`demo-images__item border ${i === 0 && "border-l-0"} ${i === miniImagesData1.length - 1 && "border-r-0"}`}
                                >
                                    <img
                                        src={img}
                                        alt="trusted brands logo"
                                        className="demo-images__img"
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="demo-images__row">
                            {miniImagesData2.map((img, i) => (
                                <div
                                    key={i}
                                    className={`demo-images__item border ${i === 0 && "border-l-0"} ${i === miniImagesData2.length - 1 && "border-r-0"}`}
                                >
                                    <img
                                        src={img}
                                        alt="trusted brands logo"
                                        className="demo-images__img"
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="demo-images__row">
                            {miniImagesData3.map((img, i) => (
                                <div
                                    key={i}
                                    className={`demo-images__item border ${i === 0 && "border-l-0"} ${i === miniImagesData3.length - 1 && "border-r-0"}`}
                                >
                                    <img
                                        src={img}
                                        alt="trusted brands logo"
                                        className="demo-images__img"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="demo-images demo-images-xs">
                        <div className="demo-images__row max-500:gap-2">
                            {min768ImageData1.map((img, i) => (
                                <div
                                    key={i}
                                    className={`demo-images__item border ${i === 0 && "border-l-0"} ${i === min768ImageData1.length - 1 && "border-r-0"} max-500:h-[85px] max-500:w-[100px]`}
                                >
                                    <img
                                        src={img}
                                        alt="trusted brands logo"
                                        className="demo-images__img max-500:max-h-[65px] max-500:max-w-[80px]"
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="demo-images__row max-500:gap-2">
                            {min768ImageData2.map((img, i) => (
                                <div
                                    key={i}
                                    className={`demo-images__item border ${i === 0 && "border-l-0"} ${i === min768ImageData2.length - 1 && "border-r-0"} max-500:h-[85px] max-500:w-[100px]`}
                                >
                                    <img
                                        src={img}
                                        alt="trusted brands logo"
                                        className="demo-images__img max-500:max-h-[65px] max-500:max-w-[80px]"
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="demo-images__row max-500:gap-2">
                            {min768ImageData3.map((img, i) => (
                                <div
                                    key={i}
                                    className={`demo-images__item border ${i === 0 && "border-l-0"} ${i === min768ImageData3.length - 1 && "border-r-0"} max-500:h-[85px] max-500:w-[100px]`}
                                >
                                    <img
                                        src={img}
                                        alt="trusted brands logo"
                                        className="demo-images__img max-500:max-h-[65px] max-500:max-w-[80px]"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="demo-bottom-img">
                <img src={grid} alt="Background Image" style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    color: "transparent"
                }}/>
            </div>
        </div>
    );
}

export default LoginPage;
