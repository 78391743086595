import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

const LINE_TEXT = [
  "Connect your store to the Priya Pricing AI.",
  "Priya AI will analyze your product's pricing data and compare it with market.",
  "The AI will generate a free report on how optimized your prices are and what can be improved.",
];

const GuideSection = ({ modalToggle }) => {
  const [windowSize, setWindowSize] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setWindowSize(true);
      } else {
        setWindowSize(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  return (
    <>
      <p className="ff-inter-light text-darkish-gray font-gradient-text text-center">
        USE AI TO ANALYZE YOUR PRICE OPTIMIZATION LEVELS
      </p>
      <div
        className="text-center"
        style={{
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        {windowSize ? (
          <h1
            className="ff-inter-regular text-dark px-lg-5 mx-lg-5 font-size-30"
            style={{ lineHeight: "40px", textAlign: "left" }}
          >
            Find out how optimal your product's prices are vs the market.
          </h1>
        ) : (
          <h1
            className="ff-inter-regular text-dark px-lg-5 mx-lg-5 font-size-30"
            style={{ lineHeight: "40px" }}
          >
            Find out how optimal your product's <br /> prices are vs the market.
          </h1>
        )}
        <Row className="my-4 flex-wrap" style={{ gap: "2rem 1rem" }}>
          {Array.from({ length: 3 }).map((_, idx) => {
            return (
              <Col
                className="d-flex"
                style={{
                  minWidth: "240px",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    require(`../../assets/img/Priya_AI/icon_step_${
                      idx + 1
                    }.png`).default
                  }
                  style={{ zIndex: 5, position: "relative", width: "100px" }}
                />
                <img
                  src={
                    require(`../../assets/img/Priya_AI/step_${
                      idx + 1
                    }_image.png`).default
                  }
                  style={{
                    marginTop: "-1.5rem",
                    width: windowSize ? "85%" : "auto",
                  }}
                />
                <p
                  className="text-dark ff-inter-light mt-3"
                  style={{
                    fontSize: "22px",
                    width: "80%",
                  }}
                >
                  {LINE_TEXT[idx]}
                </p>
              </Col>
            );
          })}
        </Row>
        <div className="gap-2 d-flex justify-content-center">
          <button
            onClick={modalToggle}
            className="ff-bold mt-3 w-full py-3"
            style={{
              color: "white",
              backgroundColor: "black",
              borderRadius: "100px",
              paddingLeft: 80,
              paddingRight: 80,
            }}
          >
            GET STARTED
          </button>
        </div>
      </div>
    </>
  );
};

export default GuideSection;
