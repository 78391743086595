import React from "react";
import { Col, Row, Table } from "reactstrap";
import Carousel from "react-elastic-carousel";

import './roadmap.css'

const Roadmap = () => {
  const slides = [
    {
      graph: (
        <img
          src={require("../../../assets/img/Priya_AI/65%.png").default}
          className="img-fluid"
        />
      ),
      image: (
        <img
          src={require("../../../assets/img/Priya_AI/jacket.png").default}
          width={80}
          height={80}
        />
      ),
      title: "Apparel",
    },
    {
      graph: (
        <img
          src={require("../../../assets/img/Priya_AI/87%.png").default}
          className="img-fluid"
        />
      ),
      image: (
        <img
          src={require("../../../assets/img/Priya_AI/beauty.png").default}
          width={80}
          height={80}
        />
      ),
      title: "Beauty Products",
    },
    {
      graph: (
        <img
          src={require("../../../assets/img/Priya_AI/72%.png").default}
          className="img-fluid"
        />
      ),
      image: (
        <img
          src={require("../../../assets/img/Priya_AI/electronics.png").default}
          width={150}
          height={80}
        />
      ),
      title: "Electronics",
    },
    {
      graph: (
        <div className="d-flex align-items-end justify-content-center">
          <div className="mr-1 d-flex flex-column align-items-end justify-content-center">
            <span className="text-gray mb-0">37%</span>
            <img
              src={require("../../../assets/img/Priya_AI/37-pet.png").default}
            />
          </div>
          <div className="d-flex flex-column align-items-end justify-content-center">
            <span className="text-banner mb-0">63%</span>
            <img
              src={require("../../../assets/img/Priya_AI/63-pet.png").default}
            />
          </div>
        </div>
      ),
      image: (
        <img
          src={require("../../../assets/img/Priya_AI/pet.png").default}
          width={80}
          height={80}
        />
      ),
      title: "Pet Products",
    },
    {
      graph: (
        <img
          src={require("../../../assets/img/Priya_AI/77%.png").default}
          className="img-fluid"
        />
      ),
      image: (
        <img
          src={require("../../../assets/img/Priya_AI/furniture.png").default}
          width={80}
          height={80}
        />
      ),
      title: "Furniture",
    },
    {
      graph: (
        <img
          src={require("../../../assets/img/Priya_AI/57%.png").default}
          className="img-fluid"
        />
      ),
      image: (
        <img
          src={require("../../../assets/img/Priya_AI/household.png").default}
          width={80}
          height={80}
        />
      ),
      title: "Household Goods",
    },
    {
      graph: (
        <img
          src={require("../../../assets/img/Priya_AI/65%.png").default}
          className="img-fluid"
        />
      ),
      image: (
        <img
          src={require("../../../assets/img/Priya_AI/computer.png").default}
          width={150}
          height={80}
        />
      ),
      title: "Computer Parts",
    },
  ];

  const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 300, itemsToShow: 2 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 992, itemsToShow: 4 },
  ];
  return (
    <>
      <section data-aos="zoom-in">
        <Row>
          <Col xl="8" xxl="6">
            <p id="main-heading-text" className=" text-banner font-gradient-text text-uppercase">
              humans vs Priya
            </p>
            <h2 id="main-subtext" className="text-white work-head mt-3">
              On average, Priya achieves 73% better price efficiency compared to
              humans setting prices.
            </h2>
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <img
                  src={
                    require("../../../assets/img/Priya_AI/human.png").default
                  }
                  className="img-fluid"
                />
                <p className="text-gray mb-0 ml-2 tool-text ">HUMAN</p>
              </div>
              <div className="d-flex align-items-center ml-5">
                <img
                  src={
                    require("../../../assets/img/Priya_AI/priya_ai.png").default
                  }
                  className="img-fluid"
                />
                <p className="text-gray text-banner mb-0 ml-2 tool-text">Priya</p>
              </div>
            </div>
            <hr />
          </Col>
        </Row>
        <Row data-aos="zoom-in" className="graph-items d-xl-none">
          <Carousel breakPoints={breakPoints}>
            {slides.map((slide, index) => {
              return (
                <div className="mr-3 d-flex flex-column align-items-center justify-content-end">
                  <div>{slide.graph}</div>
                  <div className="mt-4">{slide.image}</div>
                  <p className="text-gray font-style mt-4 img-footer-text">
                    {slide.title}
                  </p>
                </div>
              );
            })}
          </Carousel>
        </Row>
        <div className="nft-table d-none d-xl-block">
          <Table borderless className="mb-0 mt-2">
            <tbody>
              <div className="d-flex justify-content-between align-items-end bar-chart border-top-bottom py-4">
                <div className="div-size text-center">
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/65%.png").default
                    }
                    className="img-fluid"
                  />
                </div>
                <div className="div-size text-center ">
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/87%.png").default
                    }
                    className="img-fluid"
                  />
                </div>
                <div className="div-size text-center">
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/72%.png").default
                    }
                    className="img-fluid"
                  />
                </div>
                <div className="div-size text-center">
                  <div className="d-flex align-items-end justify-content-center">
                    <div className="mr-1">
                      <p className="text-gray mb-0">37%</p>
                      <img
                        src={
                          require("../../../assets/img/Priya_AI/37-pet.png")
                            .default
                        }
                      />
                    </div>
                    <div>
                      <p className="text-banner mb-0">63%</p>
                      <img
                        src={
                          require("../../../assets/img/Priya_AI/63-pet.png")
                            .default
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="div-size text-center">
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/77%.png").default
                    }
                    className="img-fluid"
                  />
                </div>
                <div className="div-size text-center">
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/57%.png").default
                    }
                    className="img-fluid"
                  />
                </div>
                <div className="div-size text-center">
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/65%.png").default
                    }
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-end bar-chart mt-4">
                <div className="d-flex flex-column align-items-center div-size">
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/jacket.png").default
                    }
                  />
                  <p className="text-gray font-style ff-light mt-3">Apparel</p>
                </div>
                <div className="d-flex flex-column align-items-center div-size">
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/beauty.png").default
                    }
                  />
                  <p className="text-gray font-style ff-light mt-3">
                    Beauty Products
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center div-size">
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/electronics.png")
                        .default
                    }
                  />
                  <p className="text-gray font-style ff-light mt-3">
                    Electronics
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center div-size">
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/pet.png").default
                    }
                  />
                  <p className="text-gray font-style ff-light mt-3">
                    Pet Products
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center div-size">
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/furniture.png")
                        .default
                    }
                  />
                  <p className="text-gray font-style ff-light mt-3">
                    Furniture
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center div-size">
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/household.png")
                        .default
                    }
                  />
                  <p className="text-gray font-style ff-light mt-3">
                    Household Goods
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center div-size ">
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/computer.png")
                        .default
                    }
                  />
                  <p className="text-gray font-style ff-light mt-3">
                    Computer Parts
                  </p>
                </div>
              </div>
            </tbody>
          </Table>
        </div>
      </section>
    </>
  );
};

export default Roadmap;
