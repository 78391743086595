import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

const FEATURE_LINES = [
  "Your current price efficiency",
  "Suggested optimizations",
  "Est. revenue at different prices",
  "Estimated lost revenue",
  "Predictive pricing analytics",
];

const AnalysisSection = ({ modalToggle }) => {
  const [windowSize, setWindowSize] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setWindowSize(true);
      } else {
        setWindowSize(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  return (
    <div className="px-lg-4">
      <h1
        className="ff-inter-regular text-dark font-size-30"
        style={{ lineHeight: "2.5rem" }}
      >
        An in-depth analysis on your products' <br /> prices, and how to improve
        them.
      </h1>
      <Row
        className="align-items-center"
        style={{ marginTop: windowSize ? "0" : "4rem" }}
      >
        <Col xs="12" md="5" className="mx-lg-3 align-self-start">
          {FEATURE_LINES.map((line, index) => (
            <Row
              key={index}
              className="align-items-center my-3 my-lg-4 justify-content-center justify-content-md-start"
            >
              <div
                className="d-flex align-items-center"
                style={{ width: "100%", maxWidth: "100%" }}
              >
                <img
                  src={
                    require("../../assets/img/Priya_AI/checked_icon.png")
                      .default
                  }
                  style={{
                    width: "2.5em",
                    height: "2.5em",
                    marginRight: "1rem",
                  }}
                />
                <p
                  className="ff-inter-regular text-gray"
                  style={{
                    marginBottom: 0,
                    fontSize: "clamp(1rem, 2vw, 1.5rem)",
                    flexGrow: 1,
                  }}
                >
                  {line}
                </p>
              </div>
            </Row>
          ))}
        </Col>
        <Col
          xs="12"
          md="6"
          className="d-flex justify-content-center justify-content-md-start mt-5 mb-5 mb-md-0 mt-md-0"
        >
          <img
            src={
              require("../../assets/img/Priya_AI/analysis_combined.png").default
            }
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "25rem",
              objectFit: "contain",
              marginLeft: "auto",
            }}
          />
        </Col>
      </Row>
      <div className="gap-2 d-flex">
        <button
          onClick={modalToggle}
          className="ff-bold mt-3 w-full py-3"
          style={{
            color: "white",
            backgroundColor: "black",
            borderRadius: "100px",
            paddingLeft: 40,
            paddingRight: 40,
          }}
        >
          ANALYZE MY PRODUCTS
        </button>
      </div>
    </div>
  );
};

export default AnalysisSection;
