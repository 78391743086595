import React from 'react'
import './ui.css'
import {Link, useHistory} from "react-router-dom";

const SecondaryButton = ({ backgroundColor, color, title, borderColor, borderWidth, className }) => {

    return (
        <div className={`secondary-btn-div ${className}`} style={{ backgroundColor: backgroundColor ? backgroundColor : 'transparent', color: color ? color : 'white', borderColor: borderColor ? borderColor : '#999999', borderWidth: borderWidth ? borderWidth : '2px', cursor: 'pointer' }}>
            <Link to="/demo">
                {title}
            </Link>
        </div>
    )
}
export default SecondaryButton
