import Footer from 'components/Footer'
import IndexNavbar from 'components/Navbars/IndexNavbar'
import React, { useEffect } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { AnchorScroll } from 'helper';
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from 'react-router-dom';

const ArticleThree = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Priya | Research | 11 best Shopify plugins for your online store</title>
                <meta name="title" content="Priya | Research | 11 best Shopify plugins for your online store" />
                <meta name="description" content="Here are 11 must-have plugins that we recommend adding to your Shopify website." />
            </Helmet>
            <div className='bg-dark'>
                <section className="py-0 nav-section">
                    <IndexNavbar className="fixed-top" />
                </section>
                <section className='my-sm-5'>
                    <Row className='align-items-center'>
                        <Col lg="10" xl="7">
                            <p className='ff-inter-regular'>Feb 7, 2024</p>
                            <h2 className="text-uppercase font-banner-research mb-0 ff-light text-banner mr-sm-4 mt-4">What Separates Priya from other AI pricing tools?</h2>
                            <p className="text-gray font-style ff-light text-22 mt-4">AI Pricing tools can help businesses to reach higher sales numbers, save valuable time, and compete in the increasingly competitive market. Here are a few benefits that an AI pricing tool can provide your business.</p>
                        </Col>
                        <Col lg="2" xl="5">
                        </Col>
                    </Row>
                </section>
            </div>
            <div className='bg-white'>
                <section>
                    <h1 className='ff-book text-dark text-center text-35'>What Separates Priya from other AI pricing tools?</h1>
                    <p className='ff-inter-regular text-dark text-16 mt-4'>Studies have shown that businesses may lose up to 35%+ in potential sales due to bad pricing. Because of this, having the optimal pricing for one’s products is absolutely vital. And in today’s tech-driven world, investing in an AI pricing tool is the best way to quickly and effectively price your store’s products.</p>
                    <p className='ff-inter-regular text-dark text-16 mt-4'>Priya AI is the next step in advanced online pricing tools, utilizing a deep learning model that finds the best prices for products while being much faster and more accurate than manual pricing.</p>
                    <div className='pt-5'>
                        <h2 className='ff-book text-dark mb-1 text-30'>Benefits of Using an AI Pricing Tool</h2>
                        <p className='ff-inter-regular text-dark text-16 mt-2'>AI Pricing tools can help businesses to reach higher sales numbers, save valuable time, and compete in the increasingly competitive market. Here are a few benefits that an AI pricing tool can provide your business:</p>
                        <ul>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Save Time:</span> Pricing your store’s products can take a lot of valuable time, especially if you
                                carry a large amount of items. Moreover, products typically change in price over time,
                                meaning that those who price their products manually will also have to change those
                                prices one at a time if they want to lower or raise prices. Through the use of an AI pricing
                                tool, you can automate the entire process of pricing and repricing your products.</li>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>More Accurate:</span> Not only are AI pricing tools great at saving you time, but chances are
                                they are also much more accurate in finding the best price for your products – which
                                directly results in more sales and higher revenue for your business. Studies have shown
                                that Priya AI can be up to 73% more accurate at pricing store products than humans.
                                This adds up fast and can make the difference between success and failure for your
                                business.</li>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Maximize ROI:</span> AI pricing tools are capable of analyzing your store’s historical sales
                                data, along with competitor’s pricing and other information to implement dynamic pricing
                                strategies and personalization pricing strategies that will help to increase your sales
                                numbers and maximize ROI.</li>
                        </ul>
                    </div>
                    <div className='pt-5'>
                        <h2 className='ff-book text-dark mb-1 text-30'>What separates Priya from other AI pricing tools?</h2>
                        <p className='ff-inter-regular text-dark text-16 mt-2'>So we’ve explained why AI pricing tools are important, and how they can help your business.
                            But why use Priya over other AI pricing tools? What sets itself apart from the competition?
                            Here are the biggest factors that set Priya apart from other AI pricing tools:</p>
                        <ul>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Uses an Advanced Deep Learning Model:</span> Priya was developed using a proprietary
                                deep learning model. It was used to train Priya on millions of pricing data points across
                                various products, services, and industries to establish initial data that is fed to the model. This info is used to help determine an optimal "price prediction over time" on a
                                per-product basis.</li>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Constantly Updated:</span> The output is a mathematical formula that is updated up to every
                                hour on every product Priya is connected to. This formula determines how prices
                                should move over time along with other macro and micro economic conditions such as
                                market conditions, sales activity, user behavior, etc.</li>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Improves Over Time:</span> Priya was trained via both supervised and unsupervised
                                learning methods and can learn by itself how to better price products the sales and
                                product data comes in. This consists of countless amounts of price-testing, training, and
                                learning.</li>
                        </ul>
                    </div>
                    <div className='pt-5'>
                        <h2 className='ff-book text-dark mb-1 text-30'>Price Prediction Model (PPM)</h2>
                        <p className='ff-inter-regular text-dark text-16 mt-2'>Priya is powered by an advanced Price Prediction Model (PPM). Here’s what it is and how it
                            works:</p>
                        <ul>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Utilizing an Advanced Algorithm:</span> PPM is a highly trained transformer model that is
                                used to run, train, and predict product prices over time. Ultimately, its goal is to predict
                                the price that has the highest likelihood of a conversion.</li>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Interprets Macro and Micro Economic Behavior:</span> PPM has been trained to predict
                                macro and micro economic conditions including economic conditions, competitor data,
                                supply chain costs, consumer behavior, and sales data to best predict prices in real-time.</li>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Advanced Learning on a Per-Product Basis:</span> PPM enables accurate price
                                predictions on a granular per-product basis. This means that products are optimized on
                                an individualized, with every single product or service optimized separately. PPM is
                                also trained on an industry level for products ranging from electronics to apparel, and
                                everything else in between.</li>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Helping Priya Learn Through Use:</span> PPM learns through experience, meaning that
                                by connecting Priya to your store, you are helping her learn and improve pricing
                                accuracy over time. By using Priya, you’re effectively participating in training her so that
                                both you and all of the other businesses utilizing her benefit.</li>
                        </ul>
                    </div>
                    <div className='pt-5'>
                        <h2 className='ff-book text-dark mb-1 text-30'>How Priya works</h2>
                    </div>
                    <div className='pt-4'>
                        <img src={require('../../../assets/img/Research/Article3/how-priya-works.png').default} className="img-fluid width-75" />
                        <p className='ff-inter-regular text-dark text-16 mt-2'>Priya AI connects to your store to automatically update prices through the use of real-time data
                            analytics and advanced pricing algorithms. It’s fairly easy to use, though there are a few things
                            to know before starting. Here’s a quick guide on how it works:</p>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default ArticleThree