import React from 'react'
import { Row, Col } from 'reactstrap';
import Marquee from "react-marquee-slider";

const Analysis = () => {

    return (
        <section>
            <p className="ff-light text-banner font-gradient-text text-center text-uppercase mb-3 mt-4 mt-sm-0">Priya analyzes</p>
            <p className="text-gray font-style ff-light text-center mb-0">Millions of product pricing and consumer behavior data across hundreds of industries.</p>
            <div className='d-block d-sm-none mt-3 mt-sm-5'>
                <Marquee key={12} velocity={25}>
                    <div className="mr-4">
                        <img src={require('../../../assets/img/Priya_AI/cocacola-mobile.png').default} className="img-fluid" width={100} />
                    </div>
                    <div className="mr-4">
                        <img src={require('../../../assets/img/Priya_AI/southern-mobile.png').default} className="img-fluid" width={100} />
                    </div>
                    <div className="mr-4">
                        <img src={require('../../../assets/img/Priya_AI/Adobe-mobile.png').default} className="img-fluid" width={100} />
                    </div>
                    <div className="mr-4">
                        <img src={require('../../../assets/img/Priya_AI/nike-mobile.png').default} className="img-fluid" width={100} />
                    </div>
                    <div className="mr-4">
                        <img src={require('../../../assets/img/Priya_AI/adidas-mobile.png').default} className="img-fluid" width={100} />
                    </div>
                </Marquee>
            </div>
            <Row className="d-none d-sm-flex align-items-center justify-content-around mt-sm-5">
                <Col sm="1"></Col>
                <Col sm="2" className='text-center'>
                    <div>
                        <img src={require('../../../assets/img/Priya_AI/cocacola.png').default} className="img-fluid" />
                    </div>
                </Col>
                <Col sm="2" className='mt-5 mt-sm-0 text-center'>
                    <div>
                        <img src={require('../../../assets/img/Priya_AI/southern.png').default} className="img-fluid" />
                    </div>
                </Col>
                <Col sm="2" className='mt-5 mt-sm-0 text-center'>
                    <div>
                        <img src={require('../../../assets/img/Priya_AI/Adobe.png').default} className="img-fluid" />
                    </div>
                </Col>
                <Col sm="2" className='mt-5 mt-sm-0 text-center'>
                    <div>
                        <img src={require('../../../assets/img/Priya_AI/nike.png').default} className="img-fluid" />
                    </div>
                </Col>
                <Col sm="2" className='mt-5 mt-sm-0 text-center'>
                    <div>
                        <img src={require('../../../assets/img/Priya_AI/adidas.png').default} className="img-fluid" />
                    </div>
                </Col>
                <Col sm="1"></Col>
            </Row>
        </section>
    )
}

export default Analysis