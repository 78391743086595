/*!

=========================================================
* priya_ai - v1.0.0
=========================================================
* Copyright © Y CORP
*/
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AnchorScroll } from "helper";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Row,
  Col,
} from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";
import { FiMenu } from "react-icons/fi";
import { useDispatch } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import Logout from "components/Logout";
import Config from "../../configs/config.js";

export default function WhiteBGNavbar({ type }) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };

  const dispatch = useDispatch();
  const history = useHistory();

  console.log(
    secureLocalStorage.getItem("integration_process"),
    secureLocalStorage.getItem("is_loggedin")
  );

  const Integration_Process = secureLocalStorage.getItem("integration_process");
  const user_logged = secureLocalStorage.getItem("is_loggedin");

  return (
    <>
      <Navbar expand="lg" className="bg-nav px-0">
        <div className="w-100">
          <Row className="d-flex align-items-center justify-content-center py-2">
            <Col lg="2" xl="2" className={type === "ppm" ? "px-0" : ""}>
              <div>
                <NavbarBrand
                  to="/"
                  tag={Link}
                  id="navbar-brand"
                  onClick={() => AnchorScroll()}
                >
                  <img
                    src={
                      require("../../assets/img/Priya_AI/logo-black.png")
                        .default
                    }
                    alt="logo"
                    className="img-fluid logo-beta"
                    width={185}
                  />
                </NavbarBrand>
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler navbar-toggler mt-0"
                  onClick={toggleCollapse}
                >
                  <FiMenu className="text-black" size="24" />
                </button>
              </div>
            </Col>
            <Col lg="6" xl="7" className="text-lg-right">
              <Collapse
                className={"justify-content-center " + collapseOut}
                navbar
                isOpen={collapseOpen}
                onExiting={onCollapseExiting}
                onExited={onCollapseExited}
              >
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column flex-lg-row justify-content-between">
                    <Nav className="nav-link-space d-flex flex-column flex-lg-row">
                      <NavItem>
                        <Link
                          to="/ppm-1"
                          className={`ff-book font-style px-lg-3 mr-2 mr-xl-5 ${
                            !window.location.href.includes("")
                              ? "navtext-active"
                              : "nav-text"
                          }`}
                        >
                          PPM-1
                        </Link>
                      </NavItem>
                      <NavItem className="mt-2 mt-lg-0">
                        <Link
                          to="/pricing"
                          className={`ff-book font-style px-lg-3 mr-2 mr-xl-5 ${
                            window.location.pathname == "/pricing"
                              ? "navtext-active"
                              : "nav-text"
                          }`}
                        >
                          Pricing
                        </Link>
                      </NavItem>
                      <NavItem className="mt-2 mt-lg-0">
                        <Link
                          to="/research"
                          className={`ff-book font-style px-lg-3 mr-2 mr-xl-5 ${
                            window.location.href.indexOf("/research") > -1
                              ? "navtext-active"
                              : "nav-text"
                          }`}
                        >
                          Research
                        </Link>
                      </NavItem>
                      <NavItem className="mt-2 mt-lg-0">
                        <Link
                          to="/about"
                          className="nav-text ff-book font-style px-lg-3 mr-2 mr-xl-5"
                        >
                          About
                        </Link>
                      </NavItem>
                    </Nav>
                  </div>
                  <div className="navbar-collapse-header">
                    <Row>
                      <Col className="collapse-close" xs="6">
                        <div className="nav-close cursor-pointer">
                          <AiOutlineClose
                            color="white"
                            size="40"
                            aria-expanded={collapseOpen}
                            onClick={toggleCollapse}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                {Integration_Process === "completed" ? (
                  <div
                    className="bg-btn-dark-dark d-block d-lg-none py-2 px-4 mt-4 mt-lg-0 ff-medium text-dark cursor-pointer"
                    onClick={() =>
                      (window.location.href = Config.DASHBOARD_URL)
                    }
                  >
                    Dashboard
                  </div>
                ) : Integration_Process === "not_completed" &&
                  user_logged === "done" ? (
                  <div
                    className="bg-btn-dark d-block d-lg-none py-2 px-4 mt-4 mt-lg-0 ff-medium text-banner cursor-pointer"
                    onClick={() =>
                      (window.location.href = `${Config.DASHBOARD_URL}/integration/steps`)
                    }
                  >
                    Complete Setup
                  </div>
                ) : (
                  <div
                    className="bg-btn-dark d-block d-lg-none py-2 px-4 mt-4 mt-lg-0 ff-medium text-dark cursor-pointer"
                    onClick={() =>
                      (window.location.href = `${Config.DASHBOARD_URL}/login`)
                    }
                  >
                    Login
                  </div>
                )}
                {user_logged === "done" && (
                  <div
                    className="d-block d-lg-none bg-btn-dark-dark py-2 px-3 mt-4 mt-lg-0 ff-medium text-banner cursor-pointer"
                    onClick={() => setShowConfirmation(!showConfirmation)}
                  >
                    Logout
                  </div>
                )}
              </Collapse>
            </Col>
            <Col
              lg="4"
              xl="3"
              className="text-right px-0 d-none d-lg-flex justify-content-end"
            >
              {Integration_Process === "completed" ? (
                <div
                  className="bg-btn-dark py-2 px-5 mr-3 mt-4 mt-lg-0 ff-medium text-dark cursor-pointer"
                  style={{ border: "2px solid black", borderRadius: "30px" }}
                  onClick={() => (window.location.href = Config.DASHBOARD_URL)}
                >
                  Dashboard
                </div>
              ) : Integration_Process === "not_completed" &&
                user_logged === "done" ? (
                <div
                  className="bg-btn-dark py-2 px-5 mr-3 mt-4 mt-lg-0 ff-medium text-dark cursor-pointer"
                  style={{ border: "2px solid black", borderRadius: "30px" }}
                  onClick={() =>
                    (window.location.href = `${Config.DASHBOARD_URL}/integration/steps`)
                  }
                >
                  Complete Setup
                </div>
              ) : (
                <div
                  className="bg-btn-dark py-2 px-5 mt-4 mt-lg-0 ff-medium text-dark cursor-pointer"
                  style={{ border: "2px solid black", borderRadius: "30px" }}
                  onClick={() =>
                    (window.location.href = `${Config.DASHBOARD_URL}/login`)
                  }
                >
                  Login
                </div>
              )}
              {user_logged === "done" && (
                <div
                  className="bg-btn-dark py-2 px-5 mt-4 mt-lg-0 ff-medium text-dark cursor-pointer"
                  style={{ border: "2px solid black", borderRadius: "30px" }}
                  onClick={() => setShowConfirmation(!showConfirmation)}
                >
                  Logout
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Navbar>

      <Logout
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
      />
    </>
  );
}
