import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { MdOutlineStarPurple500 } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";

const TrustSection = () => {
  const [windowSize, setWindowSize] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setWindowSize(true);
      } else {
        setWindowSize(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  return (
    <div
      className={
        windowSize
          ? "d-flex align-items-center justify-content-center"
          : "d-flex align-items-center"
      }
    >
      <Row className="align-items-center w-100">
        <Col
          xs="12"
          md="auto"
          className="d-flex justify-content-center justify-content-md-start pr-md-0 pr-lg-0"
        >
          <div className="d-flex justify-content-center">
            <img
              style={{ width: windowSize ? "60%" : "75%" }}
              src={require("../../assets/img/Priya_AI/Group 42.png").default}
            />
          </div>
        </Col>
        <Col
          xs="12"
          md="auto"
          className="d-flex flex-column align-items-center align-items-md-start mt-3 mt-md-0 pl-0"
        >
          <div className="d-flex justify-content-center">
            {Array.from({ length: 5 }).map((_, index) => (
              <MdOutlineStarPurple500 key={index} color="#000" size={22} />
            ))}
          </div>
          <span
            className="ff-book"
            style={{
              color: "#666",
              fontSize: "17px",
              textAlign: "center",
              marginTop: "5px",
              color: "#7C7C7C",
            }}
          >
            Trusted by 350+ Ecommerce Brands
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default TrustSection;
