import { useEffect } from "react";

export default function CalendlyWidget() {
    useEffect(() => {
        // Ensure the Calendly script is loaded after the component mounts
        const script = document.createElement("script");
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <>
            <div
                id="calendly-div"
                className="calendly-inline-widget h-[500px] w-[800px] min-w-[320px] max-900:w-[90vw] md:h-[675px] lg:h-[700px]"
                data-url="https://calendly.com/priyaai/30min?hide_event_type_details=1&hide_gdpr_banner=1"
            ></div>
        </>
    );
}
