import React from 'react'
import { Row, Col } from 'reactstrap'
import img1 from "../../../assets/img/Priya_AI/slide1.jpg"
import img2 from "../../../assets/img/Priya_AI/slide2.jpg"
import img3 from "../../../assets/img/Priya_AI/slide3.webp"
import img4 from "../../../assets/img/Priya_AI/slide4.webp"
import Carousel from 'react-elastic-carousel';
import { useHistory } from 'react-router-dom'
import './Slide.css'

const Slide = () => {

    const history = useHistory()

    const slides = [
        {
            image: img1,
            title: 'Harvard Business Review',
            description: 'How AI can help companies set prices more ethically.'
        },
        {
            image: img2,
            title: 'Boston Consulting Group',
            description: 'Why AI Transformations Should Start With Pricing.'
        },
        {
            image: img3,
            title: 'VentureBeat',
            description: 'How AI-Powered Pricing Improves Margins and Protects Consumers.'
        },
        {
            image: img4,
            title: 'Inc.com',
            description: '3 Ways Artificial Intelligence Can Help With Pricing.'
        }]

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 300, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 992, itemsToShow: 4 }
    ]
    const handleNavigation = (index) => {
        if (index === 0) {
            window.open("https://hbr.org/2021/03/how-ai-can-help-companies-set-prices-more-ethically", "_blank")
        } else if (index === 1) {
            window.open("https://www.bcg.com/publications/2021/ai-pricing-tranformations", "_blank")
        } else if (index === 2) {
            window.open("https://venturebeat.com/datadecisionmakers/how-ai-powered-pricing-improves-margins-and-protects-consumers/", "_blank")
        } else if (index === 3) {
            window.open("https://www.inc.com/anna-meyer/artificial-intelligence-ai-pricing.html", "_blank")
        }
    }
    return (
        <>
            <section id='slideMainSection'>
                <Row>
                    <Col xl="8" xxl="6">
                        <p className="text-banner font-gradient-text text-uppercase  " id='main-heading-text'>CASE STUDIES &amp; RESOURCES</p>
                        <h2 className='text-white mt-3 main-subtext'>Learn how we're changing the way businesses operate.
                        </h2>
                    </Col>
                </Row>
                <Row data-aos="zoom-in" id='slide-carousel'>
                    <Carousel breakPoints={breakPoints} itemPadding={[10, 20]} >
                        {slides.map((slide, index) => {
                            return (
                                <div className='cursor-pointer' onClick={() => handleNavigation(index)}>
                                    <img src={slide.image} className="image-style" />
                                    <p className=" text-banner mt-2" id='card-title'>{slide.title}</p>
                                    <p className='text-white mt-2' id='card-description'>{slide.description}</p>
                                </div>)
                        })}
                    </Carousel>
                </Row>
            </section>
        </>
    )
}

export default Slide