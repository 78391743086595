import Testimonial from "components/Testimonial";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";

const REVIEWS = [
  {
    name: "streetcrafter.com",
    type: "Apparel",
    imageFileName: "streetcrafter.png",
    reviewContent:
      "It's a no brainer for any ecommerce business to make sure there prices are as optimal as they can be.",
    starCount: 5,
  },
  {
    name: "jhintoncollections.com",
    type: "Apparel",
    imageFileName: "jhinton.png",
    reviewContent:
      "Like most industries, apparel is super competitive. Understanding where we are with our prices vs others is critical to our operation.",
    starCount: 5,
  },
  {
    name: "modaendrizzi.com",
    type: "Accessories",
    imageFileName: "endrizzi.png",
    reviewContent:
      "Using the Priya AI helped us see a full insight into our pricing metrics and how we can improve them.",
    starCount: 5,
  },
  {
    name: "tryayacaps.com",
    type: "Food & Beverage",
    imageFileName: "tryacaps.png",
    reviewContent:
      "Implementing Priya.ai was a game-changer for our e-commerce business. The AI-driven insights are invaluable for staying competitive.",
    starCount: 5,
  },
  {
    name: "enohdesigns.com",
    type: "Apparel",
    imageFileName: "enohdesigns.png",
    reviewContent:
      "We appreciate how Priya.ai’s analytics have given us a clearer understanding of our pricing strategies and their impact on sales.",
    starCount: 5,
  },
  {
    name: "puffieslippers.com",
    type: "Apparel",
    imageFileName: "puffieslippers.png",
    reviewContent:
      "Neglecting prices is a big mistake in ecommerce, and machines are just more effective at determining them.",
    starCount: 5,
  },
  {
    name: "shopbpearl.com",
    type: "Travel",
    imageFileName: "shopbypearl.com.png",
    reviewContent:
      "We ended up earning over 18% more each month by optimizing prices and using dynamic pricing.",
    starCount: 5,
  },
  {
    name: "boltnation.io",
    type: "Fashion Accessories",
    imageFileName: "boltnation.png",
    reviewContent:
      "A must-have tool for any Shopify store looking to increase revenue with an easy win.",
    starCount: 5,
  },
  {
    name: "crazycompression.com",
    type: "Apparel",
    imageFileName: "crazycompression.png",
    reviewContent:
      "We stopped manually changing around our prices every other week because now there’s Priya AI that does it automatically and more effectively.",
    starCount: 5,
  },
];

const TestimonialSection = () => {
  const [windowSize, setWindowSize] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setWindowSize(true);
      } else {
        setWindowSize(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  return (
    <div className="text-center px-lg-xl">
      <h1
        className="ff-inter-regular text-dark px-lg-5"
        style={{
          lineHeight: "40px",
          fontSize: 36,
          marginBottom: "6rem",
          textAlign: windowSize ? "left" : "center",
        }}
      >
        Priya has helped hundreds of ecommerce
        <br />
        brands optimize their prices to maximize revenue.
      </h1>
      <div className="bg-white px-lg-md py-lg-md">
        <Row>
          <Col>
            {REVIEWS.slice(0, 3).map((review) => {
              return <Testimonial {...review} />;
            })}
          </Col>
          <Col>
            {REVIEWS.slice(3, 6).map((review) => {
              return <Testimonial {...review} />;
            })}
          </Col>
          <Col>
            {REVIEWS.slice(6, 9).map((review) => {
              return <Testimonial {...review} />;
            })}
          </Col>
        </Row>
        {/* <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
            gap: "16px",
          }}
        >
          {REVIEWS.map((review) => {
            return <Testimonial {...review} />;
          })}
        </div> */}
      </div>
    </div>
  );
};

export default TestimonialSection;
